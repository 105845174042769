import React from "react";
import { Helmet } from "react-helmet";
import { Container, Grid, Header  } from "semantic-ui-react";
import styled from "styled-components";
import Layout from "../components/Layout";
import { MainHeader, StatementSection, FullStorySection} from "../helpers/styledComponents";

const PageContainer = styled(Container)`
  
`;

export default () => {

  return (
    <>
      <Helmet>
        <title>Full Story Initiative </title>
        <meta name="title" content="Full Story Initiative" />
        <meta name="og:title" content="Full Story Initiative" />
        <meta name="twitter:title" content="Full Story Initiative" />
        <meta name="og:site_name" content="Full Story Initiative" />
      </Helmet> 
      <Layout>
        <PageContainer>
          <MainHeader>
            <Header className="h1 compact" content="AUTHENTIC NARRATIVES" />
            <Header className="h2 compact" content="in television and film" />
            <Header className="h3 dark normal extraSpace" content="for a more" />
            <Header className="h0 compact">
              EQUITABLE <br/>
              FUTURE
            </Header>
            
          </MainHeader>

          <StatementSection>
            <Header as="h2" content="OUR VISION" />
            <div className="statement">
              <p>Stories that move us to imagine and shape the world we want to see: one that is rooted in equity and compassion.</p>
            </div>
          </StatementSection>

          <StatementSection>
            <Header as="h2" content="OUR MISSION" />
            <div className="statement">
              <p>The Full Story Initiative is an industry led effort designed to generate more authentic and equitable storytelling in television and film.</p>

              <p>The Initiative achieves this by creating a centralized system where creators can meaningfully engage with issue-area and community experts, and are provided with the research, resources and guidance needed to help accurately inform their work.</p>
            </div>
          </StatementSection>

          <FullStorySection>
            <Header as="h2" content="WHAT WE DO" />
            <Header as="h1"  className="title" content="The Full Story Initiative" />

            <Grid className="initiatives">
              <Grid.Row>
                <Grid.Column  mobile={16} tablet={8} computer={4}>
                  <Header as="h2">
                    Foster <br/> collaboration
                  </Header>
                  <p>
                    Between content creators and our network of activists and subject matter experts
                  </p>
                </Grid.Column>
                <Grid.Column  mobile={16} tablet={8} computer={4}>
                  <Header as="h2">
                    Provide <br /> resources
                  </Header>
                  <p>
                    For writers, directors, producers, showrunners and executives, such as issue-area guides, events, and workshops
                  </p>
                </Grid.Column>
                <Grid.Column  mobile={16} tablet={8} computer={4}>
                  <Header as="h2" content="Generate research and insights" />
                  <p>
                    To measure the cultural and business impact of authentic representation on-screen
                  </p>
                </Grid.Column>
                <Grid.Column  mobile={16} tablet={8} computer={4}>
                  <Header as="h2" content="Source stories and ideas" />
                  <p>
                    From nonprofits and activists to help develop content and IP
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </FullStorySection>

        </PageContainer>
      </Layout>
    </>
  )
};
